import { gql } from '@apollo/client';
const GETLIVESTANDINGS = gql`
  query liveStandings($filter: ListLiveStandingsInput) {
    liveStandings(filter: $filter) {
      id
      title
      segment
      tournament {
        id
        title
      }
      tournamentCategory {
        id
        category {
          name
          id
        }
      }
      standings {
        id
        place
        points
        pointsRatio
        setRatio

        entry {
          id
          place
          group {
            name
          }
          registrationRequest {
            id
            segment
            assignedSegment
            additionalSegment
            seed
            team {
              id
              title
              image
            }
            users {
              id
              user {
                id
                name
                surname
                avatar
                nation
              }
            }
          }
        }
      }
    }
  }
`;
export default GETLIVESTANDINGS;
