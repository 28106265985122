import { Fragment, useState } from 'react';
import type { ChangeEvent, FC, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Checkbox,
  Avatar,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import SearchIcon from 'src/icons/Search';
import Scrollbar from 'src/components/Scrollbar';
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import GetFlag from 'src/utils/getFlags';
import { LiveStandings } from 'src/types/LiveStandings';
import { formatSeed } from 'src/constants';
import { HelpOutline } from '@material-ui/icons';
import TeamMembers from 'src/components/shared/TeamMembers';

const CustomTableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(TableCell);

interface LiveStandingsListTableProps {
  leagues: LiveStandings[];
  title?: string;
  category?: string;
  selectedCat?: any;
  segment?: string;
}

type Sort = 'all' | 'updatedAt|asc';

interface SortOption {
  value: Sort;
  label: string;
}

const sortOptions: SortOption[] = [
  {
    label: 'All',
    value: 'all',
  },
];

const applyFilters = (leagues: LiveStandings[], query: string, filters: any): LiveStandings[] =>
  leagues?.filter((LiveStandings) => {
    let matches = true;

    if (query) {
      const searchWords = query.toLowerCase().split(' ');
      const properties = ['name', 'surname'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (searchWords.every((word) => LiveStandings?.entry?.registrationRequest?.users.some((user) => user.user[property]?.toLowerCase().includes(word)))) {
          containsQuery = true;
        }
      });
      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && LiveStandings[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });

const applyPagination = (customers: LiveStandings[], page: number, limit: number): LiveStandings[] => customers?.slice(page * limit, page * limit + limit);

const descendingComparator = (a: LiveStandings, b: LiveStandings, orderBy: string): number => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order: 'asc' | 'desc', orderBy: string) =>
  order === 'desc' ? (a: LiveStandings, b: LiveStandings) => descendingComparator(a, b, orderBy) : (a: LiveStandings, b: LiveStandings) => -descendingComparator(a, b, orderBy);

const applySort = (customers: LiveStandings[], sort: Sort): LiveStandings[] => {
  const [orderBy, order] = sort.split('|') as [string, 'asc' | 'desc'];
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = customers?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    // @ts-ignore
    const newOrder = comparator(a[0], b[0]);

    if (newOrder !== 0) {
      return newOrder;
    }

    // @ts-ignore
    return a[1] - b[1];
  });

  // @ts-ignore
  return stabilizedThis?.map((el) => el[0]);
};

const segmentOptions = [
  {
    value: 'main',
    label: 'Main',
  },
  {
    value: 'consolation',
    label: 'Consolation',
  },
  {
    value: 'qualification',
    label: 'Qualification',
  },
];

const LiveStandingsListTable: FC<LiveStandingsListTableProps> = (props) => {
  const { leagues, title, category, selectedCat, segment, ...other } = props;

  const [selectedLiveStandings, setSelectedLiveStandings] = useState<string[]>([]);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  const [query, setQuery] = useState<string>('');
  const [sort, setSort] = useState<Sort>(sortOptions[0].value);
  const [filters, setFilters] = useState<any>({
    hasAcceptedMarketing: null,
    isProspect: null,
    isReturning: null,
  });
  const enableBulkActions = selectedLiveStandings.length > 0;
  const selectedSomeLiveStandings = selectedLiveStandings.length > 0 && selectedLiveStandings.length < leagues?.length;
  const selectedAllLiveStandings = selectedLiveStandings.length === leagues?.length;

  const handleSelectAllLiveStandings = (event: ChangeEvent<HTMLInputElement>): void => {
    setSelectedLiveStandings(event.target.checked ? leagues?.map((LiveStandings) => LiveStandings.id) : []);
  };

  const handleSelectOneDocument = (event: ChangeEvent<HTMLInputElement>, customerId: string): void => {
    if (!selectedLiveStandings.includes(customerId)) {
      setSelectedLiveStandings((prevSelected) => [...prevSelected, customerId]);
    } else {
      setSelectedLiveStandings((prevSelected) => prevSelected.filter((id) => id !== customerId));
    }
  };

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuery(event.target.value);
    setPage(0);
  };

  const handleSortChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSort(event.target.value as Sort);
  };

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);
    setQuery('');
  };

  const handleLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredDocuments = applyFilters(leagues, query, filters);
  const sortedDocuments = applySort(filteredDocuments, sort);
  const paginatedDocuments = applyPagination(sortedDocuments, page, limit);
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Box
        sx={{
          width: '100%',
          alignItems: 'center',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'end',
          position: 'relative',
          gap: 2,
          m: -1,
          py: 3,
          '@media (max-width: 768px)': {
            py: 3,
          },
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            gap: 2,
            m: -1,
            pr: 2,

            width: '100%',
            '@media (max-width: 768px)': {
              mt: '-3px',
              mb: 0,
              justifyContent: 'flex-start',
            },
          }}
        >
          <Typography
            sx={{
              minWidth: '200px',
              color: '#0A2540',
              pb: '4px',
              fontFamily: 'Montserrat',
              fontWeight: '600',
              borderBottom: '2px solid #edeaea',
            }}
          >
            {title}
          </Typography>
        </Box>
      </Box>
      {leagues?.length > 0 && (
        <Box
          {...other}
          sx={{
            backgroundColor: 'background.default',
            // minHeight: "100%",
            borderRadius: '8px',
            border: '1px solid var(--stroke-1, #EDF1F6)',
            background: '#FFF',
            padding: '0 16px 16px 16px',
            width: '100%',
          }}
        >
          <Scrollbar>
            <Box sx={{ minWidth: 700 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <CustomTableCell
                      align="center"
                      sx={{
                        color: '#86909F',
                        fontFamily: 'Inter',
                        fontSize: '11px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: 'normal',
                        // padding: "16px 6px",
                      }}
                    >
                      NR
                    </CustomTableCell>
                    <CustomTableCell
                      align="center"
                      sx={{
                        color: '#86909F',
                        fontFamily: 'Inter',
                        fontSize: '11px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: 'normal',
                        // padding: "16px 6px",
                      }}
                    >
                      {/* {selectedCat?.category?.name || "All Categories"} */}
                      {selectedCat?.category?.type == 'single' ? 'Athlete' : selectedCat?.category?.type == 'doubles' ? 'Pair' : 'Team'}
                    </CustomTableCell>
                    <CustomTableCell
                      align="center"
                      sx={{
                        color: '#86909F',
                        fontFamily: 'Inter',
                        fontSize: '11px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: 'normal',
                        // padding: "16px 6px",
                      }}
                    >
                      PIG
                      <Tooltip title="Place in Group" arrow>
                        <IconButton sx={{ color: 'gray' }} component="span" size="small">
                          <HelpOutline fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    </CustomTableCell>
                    <CustomTableCell
                      align="center"
                      sx={{
                        color: '#86909F',
                        fontFamily: 'Inter',
                        fontSize: '11px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: 'normal',
                        // padding: "16px 6px",
                      }}
                    >
                      Points
                    </CustomTableCell>
                    <CustomTableCell
                      align="center"
                      sx={{
                        color: '#86909F',
                        fontFamily: 'Inter',
                        fontSize: '11px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: 'normal',
                        // padding: "16px 6px",
                      }}
                    >
                      SR
                    </CustomTableCell>
                    <CustomTableCell
                      align="center"
                      sx={{
                        color: '#86909F',
                        fontFamily: 'Inter',
                        fontSize: '11px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: 'normal',
                        // padding: "16px 6px",
                      }}
                    >
                      PR
                    </CustomTableCell>

                    {/* <CustomTableCell align="right"></CustomTableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedDocuments?.map((LiveStandings: any, index) => {
                    // const isDocumentsSelected = selectedLiveStandings.includes(
                    //   LiveStandings.id
                    // );

                    return (
                      <TableRow
                        hover
                        key={LiveStandings.id}
                        sx={{
                          borderRadius: '6px',
                          padding: '12px',
                          border: index % 2 === 0 ? '1px solid var(--stroke-1, #EDF1F6)' : '',
                          background: index % 2 === 0 ? '#FBFBFB' : '#FFFF',
                        }}
                      >
                        <CustomTableCell align="center">
                          <Typography
                            sx={{
                              color: '#0A2540',
                              fontWeight: 500,
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              lineHeight: 'normal',
                            }}
                          >
                            {index + 1}
                          </Typography>
                        </CustomTableCell>
                        <CustomTableCell align="left" sx={{ width: '1%', maxWidth: 'max-content' }}>
                          <TeamMembers
                            team={LiveStandings?.entry?.registrationRequest?.team}
                            users={LiveStandings?.entry?.registrationRequest?.users}
                            seed={LiveStandings?.entry?.registrationRequest?.seed}
                            assignedSegment={LiveStandings?.entry?.registrationRequest?.assignedSegment}
                            additionalSegment={LiveStandings?.entry?.registrationRequest?.additionalSegment}
                            segment={segment}
                          />
                        </CustomTableCell>
                        <CustomTableCell align="center">
                          <Typography
                            sx={{
                              color: '#0A2540',
                              fontWeight: 500,
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              lineHeight: 'normal',
                            }}
                          >
                            {`${LiveStandings?.entry?.group?.name?.split(' ')?.[1] || ''} ${LiveStandings?.entry?.group?.name ? '-' : ''} ${LiveStandings?.entry?.place || ''}`}
                          </Typography>
                        </CustomTableCell>
                        <CustomTableCell align="center">
                          <Typography
                            sx={{
                              color: '#0A2540',
                              fontWeight: 500,
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              lineHeight: 'normal',
                            }}
                          >
                            {LiveStandings?.points}
                          </Typography>
                        </CustomTableCell>
                        <CustomTableCell align="center">
                          <Typography
                            sx={{
                              color: '#0A2540',
                              fontWeight: 500,
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              lineHeight: 'normal',
                            }}
                          >
                            {LiveStandings?.setRatio}
                          </Typography>
                        </CustomTableCell>
                        <CustomTableCell align="center">
                          <Typography
                            sx={{
                              color: '#0A2540',
                              fontWeight: 500,
                              fontFamily: 'Inter',
                              fontSize: '12px',
                              fontStyle: 'normal',
                              lineHeight: 'normal',
                            }}
                          >
                            {LiveStandings?.pointsRatio}
                          </Typography>
                        </CustomTableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Scrollbar>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              alignItems: 'center',
              mt: '10px',
            }}
          >
            <Box
              sx={{
                marginLeft: '12px',
                display: 'flex',
                flexWrap: 'wrap',
                '@media (max-width: 525px)': {
                  mt: '10px',
                },
              }}
            ></Box>
            <TablePagination
              sx={{
                '.MuiToolbar-gutters': { paddingRight: '0px !important', paddingLeft: '0px !important' },
              }}
              component="div"
              count={filteredDocuments?.length}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 25]}
            />
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

LiveStandingsListTable.propTypes = {
  leagues: PropTypes.array.isRequired,
};

export default LiveStandingsListTable;
