import React from 'react';
import type { FC } from 'react';
import { Box, Divider, Grid, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactSVG } from 'react-svg';
import Pill from 'src/components/Pill';
import GetFlag from 'src/utils/getFlags';
import { useNavigate } from 'react-router';
import { formatSeed } from 'src/constants';
import { getShortMatchStatus } from 'src/utils/helperFunction';
import CustomChip from 'src/components/CustomComponents/CustomChip';
import LiveIcon from 'src/components/CustomComponents/LiveIcon';
import chroma from 'chroma-js';
import TeamMembers from 'src/components/shared/TeamMembers';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
const useStyles = makeStyles((theme) => ({
  container: {
    gap: '20px',
    flex: '1 0 0',
    display: 'grid',
  },
  head: {
    display: 'flex',
    padding: '9px 10px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    background: '#F6F6F6',
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '6px',
    borderRadius: '8px',
    border: '1px solid #EFEFEF',
  },
  title: {
    color: '#193660',
    fontSize: '14px',
    fontFamily: 'Inter',
  },
  headbox: {
    borderBottom: '1px solid #EFEFEF',
    display: 'flex',
    width: '100%',
  },
  headboxChild: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    alignItems: 'center',
  },
  winnerTitle: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    // [theme.breakpoints.down("xl")]: {
    //   fontSize: "10px",
    // },
  },

  looserTitle: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    // [theme.breakpoints.down("xl")]: {
    //   fontSize: "10px",
    // },
  },

  time: {
    color: 'var(--bright-blue-40, rgba(25, 54, 96, 0.40))',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
  winner: {
    color: '#193660',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  loser: {
    color: 'rgba(25, 54, 96, 0.40)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
}));
type ChildComponentProps = {
  data?: any;
  show?: boolean;
};
const CourtCard: FC<ChildComponentProps> = ({ data, show }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const getFormatedScore = (inputScore, match) => {
    const scoresByRound =
      Array.isArray(inputScore) &&
      inputScore?.reduce((acc, score) => {
        const round = score.round;
        if (!acc[round]) {
          acc[round] = [];
        }
        acc[round].push(score);
        return acc;
      }, {});

    let formattedScoresString = '';
    const formattedScores = [];
    if (typeof scoresByRound === 'object' && scoresByRound !== null) {
      Object?.keys(scoresByRound)?.forEach((round) => {
        const scoresForRound = scoresByRound[round];

        const entry1 = scoresForRound[0];
        const entry2 = scoresForRound[1];

        if (entry1 && entry2) {
          if (entry1.entry.id === match?.entry1?.id && entry2?.entry?.id === match?.entry2?.id) {
            entry1.roundFormat === 'tie'
              ? formattedScores.push(`[${entry1.score}:${entry2.score}]`)
              : entry1.roundFormat === 'roundTie'
              ? formattedScores.push(`(${entry1.score}:${entry2.score})`)
              : formattedScores.push(`${entry1.score}:${entry2.score}`);
          } else {
            entry2.roundFormat === 'tie'
              ? formattedScores.push(`[${entry2.score}:${entry1.score}]`)
              : entry2.roundFormat === 'roundTie'
              ? formattedScores.push(`(${entry2.score}:${entry1.score})`)
              : formattedScores.push(`${entry2.score}:${entry1.score}`);
          }
        }
        // formattedScoresString += formattedScores.join(" ") + " ";
      });
    }

    // Remove the trailing space if needed
    // formattedScoresString = formattedScoresString.trim();

    return formattedScores;
  };

  const shortRoundName = (roundName) => {
    switch (roundName) {
      case 'Semi-Final':
        return 'SF';
      case 'Quarter-Final':
        return 'QF';
      default:
        return roundName;
    }
  };
  return (
    <Box className={classes.container}>
      <Grid className={classes.head}>
        <Typography className={classes.title}>{data?.court}</Typography>
      </Grid>
      {data?.matches.map((match, index) => {
        return (
          <React.Fragment>
            <Grid className={classes.subContainer}>
              <Box className={classes.headbox}>
                <Box sx={{ padding: '10px', borderRight: '1px solid #EFEFEF' }}>
                  <Typography>{index + 1}</Typography>
                </Box>
                <Box className={classes.headboxChild}>
                  {match?.isMatchInProgress && (
                    <CustomChip
                      variant="primary"
                      size="xs"
                      color={chroma(process.env.REACT_APP_PRIMARY_COLOR ?? '#FF5733')
                        .alpha(0.7)
                        .hex()}
                      sx={{ gap: '5px', height: '20px', marginRight: '5px' }}
                    >
                      <LiveIcon />
                      live
                    </CustomChip>
                  )}
                  <Tooltip
                    title={`${match?.draw?.title || ''} ${match?.group ? match.group.name : shortRoundName(match?.round)} - (${
                      match?.tournamentCategory?.category?.name || match?.group?.tournamentCategory?.category?.name
                    })`}
                  >
                    <Typography className={classes.looserTitle}>
                      {`${match?.draw?.title || ''} ${match?.group ? match.group.name : shortRoundName(match?.round)} - (${
                        match?.tournamentCategory?.category?.name || match?.group?.tournamentCategory?.category?.name
                      })`}
                    </Typography>
                  </Tooltip>
                  <Typography className={classes.time}>{`${getShortMatchStatus(match?.matchStatus, '', match?.time, true) ?? ''}`}</Typography>
                </Box>
              </Box>
              <Box sx={{ width: '100%', borderBottom: '1px solid #EFEFEF' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    padding: '16px',
                  }}
                >
                  {match?.entry1?.users?.length > 0 || match?.entry1?.team?.id ? (
                    <TeamMembers
                      users={match?.entry1?.users}
                      team={match?.entry1?.team}
                      highlightWinner={match?.winner?.id === match?.entry1?.id}
                      showSeed={true}
                      seed={match?.entry1?.seed}
                      assignedSegment={match?.entry1?.assignedSegment}
                      additionalSegment={match?.entry1?.additionalSegment}
                      segment={match?.group?.segment}
                      isShortName={true}
                    />
                  ) : (
                    <Typography
                      sx={{
                        display: 'flex',
                        color: '#19366066',
                        fontFamily: 'Inter',
                        fontWeight: '500',
                        fontSize: '12px',
                        textTransform: 'uppercase',
                      }}
                    >
                      TBA
                    </Typography>
                  )}
                  {/* For King format */}
                  {(match?.entry1partner?.registrationRequest?.users?.length > 0 || match?.entry1partner?.registrationRequest?.team?.id) && (
                    <TeamMembers
                      users={match?.entry1partner?.registrationRequest?.users}
                      team={match?.entry1partner?.registrationRequest?.team}
                      highlightWinner={match?.winner?.id === match?.entry1partner?.registrationRequest?.id}
                      showSeed={true}
                      seed={match?.entry1partner?.registrationRequest?.seed}
                      assignedSegment={match?.entry1partner?.registrationRequest?.assignedSegment}
                      additionalSegment={match?.entry1partner?.registrationRequest?.additionalSegment}
                      segment={match?.group?.segment}
                      isShortName={true}
                    />
                  )}
                </Box>
                <Box sx={{ padding: '0px 16px' }}>
                  {' '}
                  <Divider>
                    <Typography sx={{ fontFamily: 'Inter', fontSize: '10px' }}>VS </Typography>
                  </Divider>{' '}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    padding: '16px',
                  }}
                >
                  {match?.entry2?.users?.length > 0 || match?.entry2?.team?.id ? (
                    <TeamMembers
                      users={match?.entry2?.users}
                      team={match?.entry2?.team}
                      highlightWinner={match?.winner?.id === match?.entry2?.id}
                      showSeed={true}
                      seed={match?.entry2?.seed}
                      assignedSegment={match?.entry2?.assignedSegment}
                      additionalSegment={match?.entry2?.additionalSegment}
                      segment={match?.group?.segment}
                      isShortName={true}
                    />
                  ) : (
                    <Typography
                      sx={{
                        display: 'flex',
                        color: '#19366066',
                        fontFamily: 'Inter',
                        fontWeight: '500',
                        fontSize: '12px',
                        textTransform: 'uppercase',
                      }}
                    >
                      TBA
                    </Typography>
                  )}
                  {/* For King format */}
                  {(match?.entry2partner?.registrationRequest?.users?.length > 0 || match?.entry2partner?.registrationRequest?.team?.id) && (
                    <TeamMembers
                      users={match?.entry2partner?.registrationRequest?.users}
                      team={match?.entry2partner?.registrationRequest?.team}
                      highlightWinner={match?.winner?.id === match?.entry2partner?.registrationRequest?.id}
                      showSeed={true}
                      seed={match?.entry2partner?.registrationRequest?.seed}
                      assignedSegment={match?.entry2partner?.registrationRequest?.assignedSegment}
                      additionalSegment={match?.entry2partner?.registrationRequest?.additionalSegment}
                      segment={match?.group?.segment}
                      isShortName={true}
                    />
                  )}
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '20px',
                  minHeight: '40px',
                  padding: '7px 12px',
                }}
              >
                <CustomChip size="xs" variant="secondary" sx={{ gap: '10px', height: '26px', paddingInline: '20px', width: '100%', minWidth: 'max-content', maxWidth: 'max-content' }}>
                  {match?.winner?.id === match?.entry1?.id || match?.winner?.id === match?.entry1partner?.registrationRequest?.id ? <EmojiEventsIcon sx={{ fontSize : '15px' }} /> : ''}
                  {typeof match?.score === 'string'
                    ? `${match?.score ?? ''} ${match?.scoreType ?? ''}`
                    : getFormatedScore(match?.score, match)?.map((text) => <Typography>{`${text ?? ''} ${match?.scoreType ?? ''}`}</Typography>)}
                  {match?.winner?.id === match?.entry2?.id || match?.winner?.id === match?.entry2partner?.registrationRequest?.id ? <EmojiEventsIcon sx={{ fontSize : '15px' }} /> : ''}
                </CustomChip>{' '}
              </Box>
            </Grid>
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default CourtCard;
