import React, { FC, Fragment, useEffect, useState } from 'react';
import { Box, Typography, Divider, Table, TableHead, TableRow, TableCell, TableBody, makeStyles, withStyles, useMediaQuery, useTheme, Grid } from '@material-ui/core';
import Scrollbar from 'src/components/Scrollbar';
import { ReactSVG } from 'react-svg';
import moment from 'moment';
import Cookies from 'js-cookie';
import GetFlag from 'src/utils/getFlags';
import { formatSeed } from 'src/constants';
import { useNavigate } from 'react-router';
import { getShortMatchStatus } from 'src/utils/helperFunction';
import MatchItem from 'src/components/dashboard/MatchItem';
import TeamMembers from 'src/components/shared/TeamMembers';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#193660',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
  },
  winner: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    [theme.breakpoints.down('xl')]: {
      fontSize: '10px',
    },
  },
  loser: {
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    [theme.breakpoints.down('xl')]: {
      fontSize: '10px',
    },
  },
  ponits: {
    color: '#193660',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    [theme.breakpoints.down('xl')]: {
      fontSize: '10px',
    },
  },
  heading: {
    color: '#86909F',
    fontFamily: 'Inter',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  text: {
    color: '#193660',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    [theme.breakpoints.down('xl')]: {
      fontSize: '10px',
    },
  },
  head: {
    color: '#193660',
    fontFamily: 'Montserrat',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  container: {
    padding: '0px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    width: '100%',
  },
  buttonContainer: {
    display: 'inline-flex',
    alignItems: 'flex-start',
    gap: '10px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    marginBottom: '10px',
  },
  downloadBtn: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '8px',
    border: '1px solid #EFEFEF',
    fontSize: '13px',
  },
}));

type MatchesListTable = {
  groupMatchesData: any;
  groupID: number;
  name?: string;
  selectedCat?: any;
  catName?: string;
  segment?: string;
  highlightText?: any;
  groupRefs?: any;
  userRefs?: any;
  group?: any;
};
const CustomTableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(TableCell);

const MatchesListTable: FC<MatchesListTable> = ({ groupMatchesData, name, groupID, selectedCat, catName, segment, group }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const [editMatch, setEditMatch] = useState<boolean>(false);
  const [editMatchDate, setEditMatchDate] = useState<boolean>(false);
  const [editMatchData, setEditMatchData] = useState<object>({});

  const handleEditMatch = (match) => {
    setEditMatchData(match);
    setEditMatch(true);
  };

  const handleEditMatchDate = (match) => {
    setEditMatchData(match);
    setEditMatchDate(true);
  };
  const getFormatedScore = (inputScore, match) => {
    const scoresByRound = inputScore?.reduce((acc, score) => {
      const round = score.round;
      if (!acc[round]) {
        acc[round] = [];
      }
      acc[round].push(score);
      return acc;
    }, {});

    let formattedScoresString = '';

    if (typeof scoresByRound === 'object' && scoresByRound !== null) {
      Object?.keys(scoresByRound)?.forEach((round) => {
        const scoresForRound = scoresByRound[round];
        const formattedScores = [];

        const entry1 = scoresForRound[0];
        const entry2 = scoresForRound[1];

        if (entry1 && entry2) {
          if (entry1.entry.id === match?.entry1?.id && entry2?.entry?.id === match?.entry2?.id) {
            entry1.roundFormat === 'tie'
              ? formattedScores.push(`[${entry1.score}:${entry2.score}]`)
              : entry1.roundFormat === 'roundTie'
              ? formattedScores.push(`(${entry1.score}:${entry2.score})`)
              : formattedScores.push(`${entry1.score}:${entry2.score}`);
          } else {
            entry2.roundFormat === 'tie'
              ? formattedScores.push(`[${entry2.score}:${entry1.score}]`)
              : entry2.roundFormat === 'roundTie'
              ? formattedScores.push(`(${entry2.score}:${entry1.score})`)
              : formattedScores.push(`${entry2.score}:${entry1.score}`);
          }
        }
        formattedScoresString += formattedScores.join(' ') + ' ';
      });
    }

    // Remove the trailing space if needed
    formattedScoresString = formattedScoresString
      .trim()
      .replace(/\s+\(/g, '(') // Remove space before opening (
      .replace(/\)\s+/g, ') ') // Ensure a single space after closing )
      .replace(/\]\s+/g, '] '); // Ensure a single space after closing ]
    return formattedScoresString;
  };
  return (
    <Box sx={{ width: '100%' }}>
      {groupMatchesData?.length > 0 && (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ margin: '10px 0' }}>
            <Typography
              sx={{
                color: '#193660',
                fontFamily: 'Montserrat',
                fontSize: '15px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: 'normal',
                padding: '0px 8px',
              }}
            >
              {name}
            </Typography>
            {!selectedCat && (
              <Box
                sx={{
                  padding: '0px 8px',
                  position: 'relative',
                  display: 'flex',
                  fontSize: '12px',
                }}
              >
                {catName}{' '}
                <Box
                  sx={{
                    fontSize: '10px',
                    ml: 0.2,
                    mt: -0.5,
                    // position:"absolute"
                  }}
                >
                  {segment}
                </Box>
              </Box>
            )}
          </Box>
          {/* <Divider /> */}
          {!isMobile ? (
            <Scrollbar style={{ height: 'auto' }}>
              <Box
                sx={{
                  minWidth: 700,
                  borderRadius: '6px',
                  border: '1px solid #EDF1F6',
                  background: '#FFF',
                  padding: '0px 8px 8px 8px',
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <CustomTableCell align="left" className={classes.heading}>
                        Date
                      </CustomTableCell>
                      <CustomTableCell align="left" className={classes.heading}>
                        Court
                      </CustomTableCell>
                      <CustomTableCell align="left" className={classes.heading}>
                        Team1
                      </CustomTableCell>
                      <CustomTableCell align="left" className={classes.heading}></CustomTableCell>
                      <CustomTableCell align="left" className={classes.heading}>
                        Team2
                      </CustomTableCell>
                      <CustomTableCell align="left" className={classes.heading}>
                        Results
                      </CustomTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {groupMatchesData?.map((match: any, index: any) => (
                      <Fragment key={match.id}>
                        <TableRow
                          // ref={(el) => (groupRefs.current[match.id] = el)}
                          hover
                          sx={{
                            border: index % 2 === 0 ? '1px solid var(--stroke-1, #EDF1F6)' : '',
                            background: index % 2 === 0 ? '#FAFAFA' : '#FFFF',
                          }}
                        >
                          <CustomTableCell
                            // sx={{ minWidth: { md: "250px", xs: "225px" } }}
                            align="left"
                          >
                            <Box sx={{ display: 'flex' }}>
                              <Typography
                                sx={{
                                  color: '#0A2540',
                                  fontFamily: 'Inter',
                                  fontSize: { lg: '12x', xs: '10px' },
                                  fontStyle: 'normal',
                                  fontWeight: 500,
                                  lineHeight: 'normal',
                                }}
                              >
                                {match?.date && match?.time ? `${moment.utc(match?.date).format('DD MMMM YYYY')} , ${getShortMatchStatus(match?.matchStatus, '~', match?.time) ?? ''}` : 'No date set'}
                              </Typography>
                            </Box>
                          </CustomTableCell>
                          <CustomTableCell
                            align="left"
                            // sx={{ minWidth: "100px" }}
                            className={classes.text}
                          >
                            <Box sx={{ display: 'flex', paddingLeft: '10px' }}>
                              {match?.court?.name ? match?.court?.name.split(' ').pop() : '-'}
                              <ReactSVG src={'/images/caretDown.svg'} style={{ paddingLeft: '8px' }} />
                            </Box>
                          </CustomTableCell>
                          <CustomTableCell
                            align="left"
                            sx={{
                              // minWidth: "190px",
                              gap: '4px',
                              flex: '1 0 0',
                              alignSelf: 'stretch',
                            }}
                          >
                            <TeamMembers
                              team={match?.entry1?.registrationRequest?.team}
                              users={match?.entry1?.registrationRequest?.users}
                              seed={match?.entry1?.registrationRequest?.seed}
                              assignedSegment={match?.entry1?.registrationRequest?.assignedSegment}
                              additionalSegment={match?.entry1?.entry?.registrationRequest?.additionalSegment}
                              segment={segment}
                              isShortName={true}
                              showSeed={true}
                              highlightWinner={match?.entry1?.id === match?.winner?.id}
                              group={group}
                            />
                            {/* Matches for king groups */}
                            <TeamMembers
                              team={match?.entry1partner?.registrationRequest?.team}
                              users={match?.entry1partner?.registrationRequest?.users}
                              seed={match?.entry1partner?.registrationRequest?.seed}
                              assignedSegment={match?.entry1partner?.registrationRequest?.assignedSegment}
                              additionalSegment={match?.entry1partner?.entry?.registrationRequest?.additionalSegment}
                              segment={segment}
                              isShortName={true}
                              showSeed={true}
                              highlightWinner={match?.entry1?.id === match?.winner?.id}
                              group={group}
                            />
                          </CustomTableCell>
                          <CustomTableCell
                            align="left"
                            // sx={{ minWidth: "100px" }}
                            className={classes.text}
                          >
                            VS.
                          </CustomTableCell>
                          <CustomTableCell
                            align="left"
                            sx={{
                              // minWidth: "190px",
                              gap: '4px',
                              flex: '1 0 0',
                              alignSelf: 'stretch',
                            }}
                          >
                            <TeamMembers
                              team={match?.entry2?.registrationRequest?.team}
                              users={match?.entry2?.registrationRequest?.users}
                              seed={match?.entry2?.registrationRequest?.seed}
                              assignedSegment={match?.entry2?.registrationRequest?.assignedSegment}
                              additionalSegment={match?.entry2?.entry?.registrationRequest?.additionalSegment}
                              segment={segment}
                              isShortName={true}
                              showSeed={true}
                              highlightWinner={match?.entry2?.id === match?.winner?.id}
                              group={group}
                            />

                            {/* Matches for king groups */}
                            <TeamMembers
                              team={match?.entry2partner?.registrationRequest?.team}
                              users={match?.entry2partner?.registrationRequest?.users}
                              seed={match?.entry2partner?.registrationRequest?.seed}
                              isShortName={true}
                              highlightWinner={match?.entry1?.id === match?.winner?.id}
                              group={group}
                            />
                          </CustomTableCell>
                          <CustomTableCell align="left">
                            <Typography sx={{ display: 'flex', width: '100%' }} className={classes.ponits}>
                              {getFormatedScore(match?.score, match) + `${match?.scoreType ? ' ' + match?.scoreType : ''}` || `Score is not set`}
                            </Typography>
                          </CustomTableCell>
                        </TableRow>
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Scrollbar>
          ) : (
            <Grid container sx={{ padding: '0 10px' }} justifyContent="space-between" spacing={2}>
              {groupMatchesData?.map((match: any, index: any) => (
                <Grid item sm={6} xs={12}>
                  <Box sx={{ background: '#FFFFFF', padding: '16px', borderRadius: '8px' }}>
                    <MatchItem key={match?.id} item={match} highlightText={true} segment={segment} />
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      )}
    </Box>
  );
};

export default MatchesListTable;
