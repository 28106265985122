import React, { FC, Fragment, useState } from 'react';
import CustomButton from '../CustomComponents/CustomButton';
import { Team, TournamentTeamMember } from 'src/__generated__/graphql';
import { Box, Stack, Typography } from '@material-ui/core';
import CustomChip from '../CustomComponents/CustomChip';
import PeopleIcon from '@material-ui/icons/People';
import CustomModal from '../CustomComponents/CustomModal';
import UserName from './UserName';

interface TeamComponentProps {
  users: TournamentTeamMember[];
  team?: Team;
  maxWidth?: string;
  highlightWinner?: boolean;
  group?: any;
}

const TeamComponent: FC<TeamComponentProps> = ({ team, users, group, maxWidth = '200px', highlightWinner }) => {
  const [openModal, setOpenModal] = useState(false);
  const handleClose = () => {
    setOpenModal(false);
  };
  return (
    <Fragment>
      <CustomButton
        size="auto"
        variant="text"
        sx={{ gap: '5px' }}
        onClick={() => {
          setOpenModal(true);
        }}
      >
        <img src={team?.image} style={{ height: '25px', width: '25px', borderRadius: '6px' }} />

        <Typography
          variant="font18"
          sx={{
            maxWidth: maxWidth,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontWeight: highlightWinner ? 'bold' : 'normal',
          }}
        >
          {team?.title || ''}
        </Typography>
        {group?.tournamentCategory?.category?.identifyTeamMember && (
          <CustomChip size="xs" variant="secondary" square sx={{ maxHeight: '20px', maxWidth: '20px', minHeight: '20px', minWidth: '20px' }}>
            <PeopleIcon sx={{ fontSize: '16px' }} />
          </CustomChip>
        )}
      </CustomButton>
      <CustomModal openOvveride={openModal} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description" title={'Team members'}>
        <Box sx={{ maxHeight: { xs: 'none', sm: '250px' }, overflow: 'auto', padding: '10px' }}>
          <Stack gap="8px">{users?.length > 0 ? users?.map((item, i) => <UserName user={item?.user} fideTitle={item?.fideTitle} />) : <Typography>No members found</Typography>}</Stack>
        </Box>
      </CustomModal>
    </Fragment>
  );
};

export default TeamComponent;
