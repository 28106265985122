import { Box, IconButton, Theme, Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Bracket, IRoundProps, SeedItem, SeedTeam, IRenderSeedProps, SingleLineSeed, Seed } from 'react-brackets';
import { ReactSVG } from 'react-svg';
import { formatSeed, parseScores, processLongName } from 'src/constants';
import GetFlag from 'src/utils/getFlags';
import { findTeamByKey, getShortMatchStatus } from 'src/utils/helperFunction';
import ScoreDisplay from './ScoreComp';
import moment from 'moment';
import TeamComponent from 'src/components/shared/TeamComponent';
import { useTournament } from '../TournamentPage';
const useStyles = makeStyles((theme) => ({
  DrawsContainer: {
    minHeight: '550px',
    borderRadius: '12px',
  },
  details: {
    width: '100%',
    paddingLeft: '0!important',
    paddingRight: '0!important',
  },
  seedTeam: {
    // background: '#FFF',
    borderRadius: '4px 4px 0px 0px',
    // borderTop: '1px solid #EDF1F6',
    // borderRight: '1px solid #EDF1F6 !important',
    // borderLeft: '1px solid #EDF1F6',
    display: 'flex',
    justifyContent: 'space-between',
    // height: '40px',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '320px',
    maxWidth: '100%',
    minWidth: '320px',
  },
  winnerTeamTitle: {
    // color: '#193660',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  loserTeamTitle: {
    color: 'rgba(25, 54, 96, 0.40)',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    display: 'flex',
    gap: '10px',
  },
  title: {
    color: '#0A2540',
    fontStyle: 'normal',
    lineHeight: 'normal',
  },
  subTitle: {
    marginTop: '2px',
    color: '#193660',
    fontFamily: 'Inter',
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
  },
  container: {
    padding: '0px',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    width: '100%',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    // flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'nowrap',
      gap: '8px',
    },
    marginBottom: '15px',
  },
  select: {
    display: 'flex',
    width: '248px',
    alignItems: 'center',
    gap: '24px',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  downloadBtn: {
    padding: '5px',
    alignItems: 'center',
    borderRadius: '4px',
    // fontSize: '12px',
  },
  label: {
    mt: -2,
    lineHeight: 1,
  },
  win: {
    boxShadow: '-4px 0px 0px 0px #00D010 inset',
  },
  loose: {
    boxShadow: '-4px 0px 0px 0px #0000 inset',
  },
  arrowContainer: {
    display: 'flex',
    gap: theme.spacing(2.5),
  },
  textfield: {
    background: '#fff',
    height: '40px',
    flexShrink: 0,
    borderRadius: '8px',
    border: 0,
    '& .MuiOutlinedInput-root': {
      height: '40px',
      flexShrink: 0,
      borderRadius: '8px',
    },
  },
  seedAddTeam: {
    borderRadius: '4px 4px 0px 0px',
    borderTop: '1px solid #EDF1F6',
    display: 'flex',
    justifyContent: 'center !important',
    alignItems: 'center',
    textTransform: 'uppercase',
    padding: '0.8rem 0.7rem !important',
    color: '#193660',
    cursor: 'pointer',
  },
  teamBackground: {
    background: `red !important`,
  },
  seedTeams: {
    padding: '0.6rem 0.7rem !important',
    borderRadius: '4px 4px 0px 0px',
    // borderTop: '1px solid #EDF1F6',
    display: 'flex',
    justifyContent: 'space-between !important',
    alignItems: 'center',
    textTransform: 'uppercase',
    // padding: '0 !important',
    color: '#193660',
    background: '#ffff',
    // height: 'max-content !important',
    // minHeight: '60px !important',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '320px',
    maxWidth: '100%',
    minWidth: '320px',
  },
  seedTeams2: {
    background: '#ffff',
    padding: '0.6rem 0.7rem !important',
    borderRadius: '4px 4px 0px 0px',
    borderTop: '1px solid #EDF1F6',
    display: 'flex',
    justifyContent: 'space-between !important',
    alignItems: 'center',
    textTransform: 'uppercase',
    // padding: '0 !important',
    // height: 'max-content !important',
    // minHeight: '60px !important',
    color: '#193660',
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: '320px',
    maxWidth: '100%',
    minWidth: '320px',
  },
  rightLine: {
    position: 'relative',
    cursor: 'pointer',
    '&::before': {
      content: '""',
      width: '1px',
      height: '40px',
      background: '#f0f0f0',
      position: 'absolute',
      left: '-15px',
      bottom: '-10px',
      [theme.breakpoints.down('sm')]: {
        left: '-10px',
        height: '30px',
      },
    },
  },
  qualWinners: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    width: '220px',
    border: '1px solid #EDF1F6',
    borderRadius: '4px',
    padding: '5px 10px',
    marginLeft: '20px',
    overflow: 'hidden',
    textWrap: 'nowrap',
    '@media (max-width: 560px)': {
      display: 'none',
    },
  },
  qualTeam: {
    textTransform: 'uppercase',
    color: '#0A2540',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  name: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    lineHeight: 'normal',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
  },
}));
const CustomSeed = ({
  seed,
  breakpoint,
  roundIndex,
  seedIndex,
  rounds,
  drawCategory,
  drawSegment,
  draw,
  drawId,
  navigate,
  setOpenModal,
  setSelectedSeed,
  setIsDisplayClub,
  search,
  currentIndexes,
  currentIndex,
  setCurrentIndexes,
  mobileView,
  index,
  selectedSeedInfo,
  setSelectedSeedInfo,
  isTeam,
}: IRenderSeedProps & {
  drawCategory: number;
  drawId: number;
  draw: any;
  drawSegment: string;
  navigate?: any;
  setOpenModal?: any;
  setSelectedSeed?: any;
  setIsDisplayClub?: any;
  search?: any;
  currentIndexes: any;
  currentIndex: number;
  setCurrentIndexes: any;
  mobileView: boolean;
  index?: any;
  selectedSeedInfo?: any;
  setSelectedSeedInfo?: any;
  isTeam?: any;
}) => {
  const { tournament } = useTournament();
  const nextSeedIndex = Math.trunc(seedIndex / 2);
  const nextSeedID = Number(rounds[roundIndex + 1]?.seeds[nextSeedIndex]?.id);
  const team = Math.floor(seedIndex / 2) === seedIndex / 2 ? 'team1' : 'team2';
  const isShowPlaceInGroup = draw?.genDraw === 'liveStandings' || draw?.genDraw === 'liveStandingsManual' || draw?.genDraw === 'fromGroups';
  const isDuprRatingEnabled = tournament?.isDuprRatingEnabled;

  const emptyMatch = findTeamByKey('emptyMatch', seed.teams);
  const teamOne = findTeamByKey('teamOne', seed.teams);
  const teamTwo = findTeamByKey('teamTwo', seed.teams);
  const addTeam1 = findTeamByKey('Team1', seed.teams);
  const addTeam2 = findTeamByKey('Team2', seed.teams);
  const setResult = findTeamByKey('setResult', seed.teams);
  const matchSkipped = findTeamByKey('matchSkipped', seed.teams);

  const classes = useStyles();
  const isLineConnector = rounds[roundIndex]?.seeds?.length === rounds[roundIndex + 1]?.seeds?.length;
  const Wrapper = isLineConnector ? SingleLineSeed : Seed;
  const isLastRound = roundIndex === rounds?.length - 1;
  const winner = seed?.teams?.find((team) => team?.id === seed?.winner?.id);
  const [addTeamData, setAddTeamData] = useState<any>({
    id: null,
    team: '',
    nextSeedID: null,
    seed: null,
    selectedEntry: null,
  });
  const prevHighlightRef = useRef<HTMLElement[]>([]);

  useEffect(() => {
    if (selectedSeedInfo.roundIndex !== null && selectedSeedInfo.seedIndex !== null) {
      const pairIndex = Math.floor(selectedSeedInfo.seedIndex / 2); // Calculate pair index
      const baseSelector = `.draw-${selectedSeedInfo.drawId}.round-${selectedSeedInfo.roundIndex}`;
      const isForward = !selectedSeedInfo?.direction;

      // Find selectors for both seeds in the pair within the specific draw
      const selectors = [
        `${baseSelector}.seed-${selectedSeedInfo.seedIndex}`,
        // `${baseSelector}.seed-${pairIndex * 2 + 1}`,
        ...(!isForward ? [`${baseSelector}.seed-${selectedSeedInfo.seedIndex + 1}`] : []),
        // ...(!isForward ? [`${baseSelector}.seed-${pairIndex * 2 + 2}`, `${baseSelector}.seed-${pairIndex * 2 + 3}`] : []),
      ];

      // Clear previous highlights
      prevHighlightRef.current.forEach((element) => {
        if (element) {
          element.classList.remove('highlighted'); // Remove the highlight background
        }
      });

      // Update the ref with new highlighted elements
      const newHighlightedElements: HTMLElement[] = [];

      setTimeout(() => {
        selectors.forEach((selector) => {
          document.querySelectorAll(selector).forEach((element) => {
            if (element instanceof HTMLElement) {
              newHighlightedElements.push(element);
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
              // element.style.background = 'yellow'; // Highlight background
              element.classList.add('highlighted');
            }
          });
        });

        // Update the ref with currently highlighted elements
        prevHighlightRef.current = newHighlightedElements;
      }, 500); // Increased delay to ensure the DOM is fully loaded
    }
  }, [selectedSeedInfo]);

  const getPlayerName = (team, playerNumber) => {
    if (team?.length > 0) {
      return `${playerNumber === 1 ? team[0]?.player1 : team[1]?.player2}`;
    } else {
      return null;
    }
  };
  const getPlayerid = (team, playerNumber) => {
    if (team?.length > 0) {
      return `${playerNumber === 1 ? team[0]?.player1Id : team[1]?.player2Id}`;
    } else {
      return null;
    }
  };
  const isClub = draw?.isDisplayParticipantsClub;
  const getPlayerFlag = (team, playerNumber) => {
    if (team?.length > 0) {
      return (
        <GetFlag
          country={playerNumber === 1 ? team[0]?.nation : team[1]?.nation}
          style={{
            width: '15px',
            height: '15px',
            marginRight: '5px',
            marginBottom: '-4px',
          }}
        />
      );
    } else {
      return null;
    }
  };
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  return (
    <Wrapper
      // mobileBreakpoint={breakpoint}
      style={{ position: 'relative', fontSize: 12, flexDirection: 'row', width: '100%' }}
      className={`draw-${index} round-${roundIndex} seed-${seedIndex} ${isLastRound && [2, 4, 16, 24, 32, 64].includes(rounds[roundIndex]?.seeds?.length) ? 'qualWinners' : ''} ${
        roundIndex !== 0 ? 'notFirst' : ''
      }`}
    >
      {mobileView && roundIndex !== 0 && (
        <IconButton
          size="small"
          sx={{
            height: '20px',
            width: '20px',
            position: 'absolute',
            left: '-10px',
            top: '87px',
            zIndex: 1,
            // left: 0,
            background: '#E3C8CD',
            // background: (theme) => theme.palette.primary.main,
            color: (theme) => theme.palette.primary.contrastText,
            ':hover': {
              color: (theme) => theme.palette.primary.main,
            },
            pointerEvents: currentIndex === 0 ? 'none' : 'auto',
          }}
          onClick={() => {
            setSelectedSeedInfo({ drawId: index, roundIndex: roundIndex - 1, seedIndex: seedIndex * 2, direction: true });
            setCurrentIndexes({
              ...currentIndexes,
              [drawId]: currentIndex - 1,
            });
          }}
        >
          <img src="/images/newRev.svg" style={{ fontSize: '14px' }} />
        </IconButton>
      )}
      {mobileView && seedIndex % 2 === 0 && roundIndex !== rounds?.length - 1 && (
        <IconButton
          size="small"
          sx={{
            height: '20px',
            width: '20px',
            position: 'absolute',
            right: '-10px',
            bottom: '-10px',
            background: '#E3C8CD',
            zIndex: 1,
            color: (theme) => theme.palette.primary.contrastText,
            ':hover': {
              color: (theme) => theme.palette.primary.main,
            },
            pointerEvents: currentIndex === rounds?.length - 1 ? 'none' : 'auto',
          }}
          onClick={() => {
            setSelectedSeedInfo({ drawId: index, roundIndex: roundIndex + 1, seedIndex: nextSeedIndex, direction: false });
            setCurrentIndexes({
              ...currentIndexes,
              [drawId]: currentIndex + 1,
            });
          }}
        >
          <img src="/images/newfor.svg" style={{ fontSize: '14px' }} />
        </IconButton>
      )}
      <SeedItem
        style={{
          // ...(!smDown && { width: '330px' }),
          width: '100%',
          minWidth: '260px',
          background: 'transparent',
          boxShadow: 'none',
        }}
      >
        <Box sx={{ position: 'relative', border: '1px solid #efefef', borderRadius: '8px', marginTop: '23px' }}>
          {/* <SeedTeam className={`${classes.seedTeam}`}>
              <Typography className={classes.winnerTeamTitle}>
                {!teamOne && !seed?.court && !teamTwo
                  ? 'TBA'
                  : `${seed?.date ? moment.utc(seed?.date).format('DD/MM') : ''}
                  ${getShortMatchStatus(seed?.matchStatus, '~', seed?.time) ?? ''} ${seed?.court ? seed?.court?.name : ''}`}
              </Typography>
            </SeedTeam> */}
          {emptyMatch && (
            <SeedTeam style={{ minHeight: isClub ? '60px' : '40px' }} className={`${classes.seedTeam}`}>
              <Typography className={classes.winnerTeamTitle}>TBA</Typography>
              <Typography className={classes.winnerTeamTitle}>
                <ReactSVG className={classes.rightLine} src="/images/drag.svg" />
              </Typography>
            </SeedTeam>
          )}
          {isTeam ? (
            <Box sx={{ padding: '5px' }}>
              <TeamComponent
                team={teamOne?.team}
                users={teamOne?.teamOne}
                maxWidth="180px"
                // onClick={() => {
                //   setSelectedSeed(seed);
                // }}
              />
            </Box>
          ) : (
            <>
              {teamOne ? (
                <SeedTeam
                  style={{ minHeight: isClub ? '60px' : '40px' }}
                  className={`${
                    search && (teamOne?.teamOne[1]?.player2?.toLowerCase()?.includes(search?.toLowerCase()) || teamOne?.teamOne[0]?.player1?.toLowerCase().includes(search?.toLowerCase()))
                      ? classes.teamBackground
                      : ''
                  }${classes.seedTeams} `}
                >
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {roundIndex === 0 && (
                        <Typography sx={{ minWidth: isDuprRatingEnabled ? '40px' : '24px', fontFamily: 'Inter', fontSize: '12px', fontWeight: 500, lineHeight: 'normal' }}>
                          {isShowPlaceInGroup
                            ? `${teamOne?.entry1group}${teamOne?.entry1pig ?? ''}`
                            : isDuprRatingEnabled
                            ? teamOne?.teamOne[0]?.duprRating
                            : teamOne?.teamOne[0]?.rank
                            ? teamOne?.teamOne[0]?.rank
                            : 0}
                        </Typography>
                      )}

                      <GetFlag
                        country={teamOne?.teamOne[0]?.nation}
                        style={{
                          width: '15px',
                          height: '10px',
                          marginRight: '5px',
                        }}
                      />
                      <Typography
                        sx={{
                          // pl: '5px',
                          fontSize: '12px',
                          // marginTop: '-2px',
                          fontWeight: seed?.winner?.id === seed?.teams[0]?.id ? 600 : 500,
                          color: seed?.winner?.id === seed?.teams[0]?.id ? process.env.REACT_APP_PRIMARY_COLOR : '#425466',
                          cursor: 'pointer',
                        }}
                        className={classes.name}
                        onClick={() => window.open(`/athlete/${teamOne?.teamOne[0]?.player1Id}/${teamOne?.teamOne[0]?.player1?.toLowerCase()?.replace(/\s+/g, '-')}?tab=overview`, '_blank')}
                      >
                        {processLongName(teamOne?.teamOne[0]?.player1)}
                        {formatSeed(seed?.teams[0], drawSegment)}
                      </Typography>
                    </Box>
                    {draw?.isDisplayParticipantsClub && (
                      <Box sx={{ height: '15px' }}>
                        {teamOne?.teamOne?.[0]?.club?.clubName && (
                          <Typography
                            sx={{
                              marginLeft: '25px',
                              fontSize: '10px',
                              textAlign: 'left',
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.8,
                              whiteSpace: 'nowrap',
                              width: '141px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            <img src="/images/club.svg" style={{ width: '14px', height: '14px' }} />
                            {teamOne?.teamOne?.[0]?.club?.clubName}
                          </Typography>
                        )}
                      </Box>
                    )}
                    {teamOne?.teamOne?.length > 1 && (
                      <>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: '5px' }}>
                          {/* {teamOne?.teamOne[1]?.rank && ( */}
                          {roundIndex === 0 && (
                            <Typography sx={{ minWidth: isDuprRatingEnabled ? '40px' : '24px', fontFamily: 'Inter', fontSize: '12px', fontWeight: 500, lineHeight: 'normal' }}>
                              {isShowPlaceInGroup ? '' : isDuprRatingEnabled ? teamOne?.teamOne[1]?.duprRating : teamOne?.teamOne[1]?.rank ? teamOne?.teamOne[1]?.rank : 0}
                            </Typography>
                          )}
                          <GetFlag
                            country={teamOne?.teamOne[1]?.nation}
                            style={{
                              width: '15px',
                              height: '10px',
                              marginRight: '5px',
                            }}
                          />
                          <Typography
                            sx={{
                              // pl: '5px',
                              fontSize: '12px',
                              // marginTop: '-2px',
                              fontWeight: seed?.winner?.id === seed?.teams[0]?.id ? 600 : 500,
                              color: seed?.winner?.id === seed?.teams[0]?.id ? process.env.REACT_APP_PRIMARY_COLOR : '#425466',

                              cursor: 'pointer',
                            }}
                            className={classes.name}
                            onClick={() => window.open(`/athlete/${teamOne?.teamOne[1]?.player2Id}/${teamOne?.teamOne[1]?.player2?.toLowerCase()?.replace(/\s+/g, '-')}?tab=overview`, '_blank')}
                          >
                            {processLongName(teamOne?.teamOne[1]?.player2)}
                          </Typography>
                        </Box>
                        {draw?.isDisplayParticipantsClub && (
                          <Box sx={{ height: '15px' }}>
                            {draw?.isDisplayParticipantsClub && teamOne?.teamOne?.[1]?.club?.clubName && (
                              <Typography
                                sx={{
                                  marginLeft: '25px',
                                  fontSize: '10px',
                                  textAlign: 'left',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 0.8,
                                  whiteSpace: 'nowrap',
                                  width: '141px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                <img src="/images/club.svg" style={{ width: '14px', height: '14px' }} />
                                {teamOne?.teamOne?.[1]?.club?.clubName}
                              </Typography>
                            )}
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {seed?.winner?.id === seed?.teams[0]?.id ? (
                      <ScoreDisplay scores1={parseScores(seed?.addScore?.addScore)?.firstArray} scores2={parseScores(seed?.addScore?.addScore)?.secondArray} winner={true} />
                    ) : (
                      <ScoreDisplay scores1={parseScores(seed?.addScore?.addScore)?.secondArray} scores2={parseScores(seed?.addScore?.addScore)?.firstArray} winner={false} />
                    )}
                  </Box>
                  {/* <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '@media print': { display: 'none !important' },
                  }}
                ></Box> */}
                </SeedTeam>
              ) : (
                <SeedTeam style={{ minHeight: isClub ? '60px' : '40px' }} className={`${classes.seedTeams2}`}>
                  {roundIndex === 0 && (
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '24px' }}>
                        <Typography
                          sx={{
                            // pl: '5px',
                            fontSize: '12px',
                            marginTop: '3px',
                            fontWeight: seed?.winner?.id === seed?.teams[0]?.id ? 600 : 500,
                            color: '#86909F',
                          }}
                          className={classes.name}
                        >
                          X
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </SeedTeam>
              )}
            </>
          )}
          {addTeam1 && (
            <SeedTeam
              style={{ minHeight: isClub ? '60px' : '40px' }}
              className={`${classes.seedAddTeam}`}
              onClick={async () => {
                await setAddTeamData({
                  id: Number(seed?.id),
                  team: 'teamOne',
                  nextSeedID: nextSeedID,
                });
              }}
            >
              <Typography className={classes.loserTeamTitle}>
                <ReactSVG style={{ cursor: 'pointer' }} src="/images/add-gray.svg" />
                {addTeam1.addTeam1}
              </Typography>
            </SeedTeam>
          )}
          {isTeam ? (
            <Box sx={{ padding: '5px' }}>
              <TeamComponent team={teamTwo?.team} users={teamTwo?.teamTwo} maxWidth="180px" />
            </Box>
          ) : (
            <>
              {teamTwo ? (
                <SeedTeam
                  style={{ minHeight: isClub ? '60px' : '40px' }}
                  className={`${
                    search && (teamTwo?.teamTwo[0]?.player1?.toLowerCase()?.includes(search?.toLowerCase()) || teamTwo?.teamTwo[1]?.player2?.toLowerCase()?.includes(search?.toLowerCase()))
                      ? classes.teamBackground
                      : ''
                  }${classes.seedTeams2}`}
                >
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {/* {teamTwo?.teamTwo[0]?.rank && ( */}
                      {roundIndex === 0 && (
                        <Typography sx={{ minWidth: isDuprRatingEnabled ? '40px' : '24px', fontFamily: 'Inter', fontSize: '12px', fontWeight: 500, lineHeight: 'normal' }}>
                          {isShowPlaceInGroup
                            ? `${teamTwo?.entry2group}${teamTwo?.entry2pig ?? ''}`
                            : isDuprRatingEnabled
                            ? teamTwo?.teamTwo[0]?.duprRating
                            : teamTwo?.teamTwo[0]?.rank
                            ? teamTwo?.teamTwo[0]?.rank
                            : 0}
                        </Typography>
                      )}
                      <GetFlag
                        country={teamTwo?.teamTwo[0]?.nation}
                        style={{
                          width: '15px',
                          height: '10px',
                          marginRight: '5px',
                        }}
                      />
                      <Typography
                        sx={{
                          // pl: '5px',
                          fontSize: '12px',
                          // marginTop: '-2px',
                          fontWeight: seed?.winner?.id === seed?.teams[1]?.id ? 600 : 500,
                          color: seed?.winner?.id === seed?.teams[1]?.id ? process.env.REACT_APP_PRIMARY_COLOR : '#425466',

                          cursor: 'pointer',
                        }}
                        className={classes.name}
                        onClick={() => window.open(`/athlete/${teamTwo?.teamTwo[0]?.player1Id}/${teamTwo?.teamTwo[0]?.player1?.toLowerCase()?.replace(/\s+/g, '-')}?tab=overview`, '_blank')}
                      >
                        {processLongName(teamTwo?.teamTwo[0]?.player1)}
                        {formatSeed(seed?.teams[1], drawSegment)}
                      </Typography>
                    </Box>
                    {draw?.isDisplayParticipantsClub && (
                      <Box sx={{ height: '15px' }}>
                        {teamTwo?.teamTwo?.[0]?.club?.clubName && (
                          <Typography
                            sx={{
                              marginLeft: '25px',
                              fontSize: '10px',
                              textAlign: 'left',
                              display: 'flex',
                              alignItems: 'center',
                              gap: 0.8,
                              whiteSpace: 'nowrap',
                              width: '141px',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            <img src="/images/club.svg" style={{ width: '14px', height: '14px' }} />
                            {teamTwo?.teamTwo?.[0]?.club?.clubName}
                          </Typography>
                        )}
                      </Box>
                    )}
                    {teamTwo?.teamTwo?.length > 1 && (
                      <>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: '5px' }}>
                          {/* {teamTwo?.teamTwo[1]?.rank && ( */}
                          {roundIndex === 0 && (
                            <Typography sx={{ minWidth: isDuprRatingEnabled ? '40px' : '24px', fontFamily: 'Inter', fontSize: '12px', fontWeight: 500, lineHeight: 'normal' }}>
                              {isShowPlaceInGroup ? '' : isDuprRatingEnabled ? teamTwo?.teamTwo[1]?.duprRating : teamTwo?.teamTwo[1]?.rank ? teamTwo?.teamTwo[1]?.rank : 0}
                            </Typography>
                          )}
                          <GetFlag
                            country={teamTwo?.teamTwo[1]?.nation}
                            style={{
                              width: '15px',
                              height: '10px',
                              marginRight: '5px',
                            }}
                          />
                          <Typography
                            sx={{
                              // pl: '5px',
                              fontSize: '12px',
                              // marginTop: '-2px',
                              fontWeight: seed?.winner?.id === seed?.teams[1]?.id ? 600 : 500,
                              color: seed?.winner?.id === seed?.teams[1]?.id ? process.env.REACT_APP_PRIMARY_COLOR : '#425466',

                              cursor: 'pointer',
                            }}
                            className={classes.name}
                            onClick={() => window.open(`/athlete/${teamTwo?.teamTwo[1]?.player2Id}/${teamTwo?.teamTwo[1].player2?.toLowerCase()?.replace(/\s+/g, '-')}?tab=overview`, '_blank')}
                          >
                            {processLongName(teamTwo?.teamTwo[1].player2)}
                          </Typography>
                        </Box>
                        {draw?.isDisplayParticipantsClub && (
                          <Box sx={{ height: '15px' }}>
                            {teamTwo?.teamTwo?.[1]?.club?.clubName && (
                              <Typography
                                sx={{
                                  marginLeft: '25px',
                                  fontSize: '10px',
                                  textAlign: 'left',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 0.8,
                                  whiteSpace: 'nowrap',
                                  width: '141px',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                <img src="/images/club.svg" style={{ width: '14px', height: '14px' }} />
                                {teamTwo?.teamTwo?.[1]?.club?.clubName}
                              </Typography>
                            )}
                          </Box>
                        )}
                      </>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {seed?.winner?.id === seed?.teams[1]?.id ? (
                      <ScoreDisplay scores1={parseScores(seed?.addScore?.addScore)?.firstArray} scores2={parseScores(seed?.addScore?.addScore)?.secondArray} winner={false} />
                    ) : (
                      <ScoreDisplay scores1={parseScores(seed?.addScore?.addScore)?.secondArray} scores2={parseScores(seed?.addScore?.addScore)?.firstArray} winner={true} />
                    )}
                  </Box>
                  {/* <Box sx={{ display: 'flex', alignItems: 'center' }}></Box> */}
                </SeedTeam>
              ) : (
                <SeedTeam style={{ minHeight: isClub ? '60px' : '40px' }} className={`${classes.seedTeams2}`}>
                  {roundIndex === 0 && (
                    <Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '24px' }}>
                        <Typography
                          sx={{
                            // pl: '5px',
                            fontSize: '12px',
                            marginTop: '3px',
                            fontWeight: seed?.winner?.id === seed?.teams[0]?.id ? 600 : 500,
                            color: '#86909F',
                          }}
                          className={classes.name}
                        >
                          X{/* Waiting */}
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </SeedTeam>
              )}
            </>
          )}
          {addTeam2 && (
            <SeedTeam
              style={{ minHeight: isClub ? '60px' : '40px' }}
              className={`${classes.seedAddTeam}`}
              onClick={async () => {
                await setAddTeamData({ id: Number(seed?.id), team: 'teamTwo' });
              }}
            >
              <Typography className={classes.loserTeamTitle}>
                <ReactSVG style={{ cursor: 'pointer' }} src="/images/add-gray.svg" />
                {addTeam2.addTeam2}
              </Typography>
            </SeedTeam>
          )}
          {/* {seed?.addScore && (
              <SeedTeam className={`${classes.seedTeams}`}>
                <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>{`${seed?.addScore?.addScore ?? ''} ${seed?.scoreType ?? ''}`}</Typography>
              </SeedTeam>
            )} */}
        </Box>
        <SeedTeam className={`${classes.seedTeam}${classes.details}`}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', height: '15px' }}>
            <Typography
              className={classes.winnerTeamTitle}
              sx={{ color: '#86909F', wordWrap: 'break-word', textAlign: 'start', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical' }}
            >
              {!teamOne && !seed?.court && !teamTwo
                ? 'TBA'
                : `${seed?.date ? moment?.utc(seed?.date).format('DD/MM') : ''}
                  ${getShortMatchStatus(seed?.matchStatus, '~', seed?.time) ?? ''} ${seed?.court ? seed?.court?.name : ''}`}
            </Typography>
            <Typography
              className={classes.winnerTeamTitle}
              sx={{ color: '#86909F', cursor: 'pointer', whiteSpace: 'nowrap' }}
              onClick={() => {
                setSelectedSeed(seed), setOpenModal(true), setIsDisplayClub(draw?.isDisplayParticipantsClub);
              }}
            >
              View Details
            </Typography>
          </Box>
        </SeedTeam>
      </SeedItem>
      {isLastRound && [2, 4, 16, 24, 32, 64].includes(rounds[roundIndex]?.seeds?.length) && (
        <Box sx={{ position: 'relative' }}>
          <Box className={`${classes.qualWinners} qualWinnerBox`} sx={{ cursor: 'pointer', background: '#fff' }}>
            <Box
              sx={{
                background: '#fff',
                padding: '0 5px',
                position: 'absolute',
                top: '5px',
                right: '5px',
                display: winner?.teamOne?.length > 0 || winner?.teamTwo?.length > 0 ? 'block' : 'none',
              }}
            >
              🏆
            </Box>
            {teamOne?.teamOne?.length === 2 ? (
              <Fragment>
                <Typography
                  className={classes.qualTeam}
                  onClick={() =>
                    navigate(
                      `/athlete/${getPlayerid(winner?.teamOne, 1) || getPlayerid(winner?.teamTwo, 1)}/${
                        getPlayerName(winner?.teamOne, 1) || getPlayerName(winner?.teamTwo, 1) || 'Qual winner'?.toLowerCase()?.replace(/\s+/g, '-')
                      }?tab=overview`,
                    )
                  }
                >
                  {getPlayerFlag(winner?.teamOne, 1) || getPlayerFlag(winner?.teamTwo, 1)}
                  {getPlayerName(winner?.teamOne, 1) || getPlayerName(winner?.teamTwo, 1) || 'Qual winner'}
                </Typography>
                <Typography
                  className={classes.qualTeam}
                  onClick={() =>
                    navigate(
                      `/athlete/${getPlayerid(winner?.teamOne, 2) || getPlayerid(winner?.teamTwo, 2)}/${
                        getPlayerName(winner?.teamOne, 2) || getPlayerName(winner?.teamTwo, 2) || 'Qual winner'?.toLowerCase()?.replace(/\s+/g, '-')
                      }?tab=overview`,
                    )
                  }
                >
                  {getPlayerFlag(winner?.teamOne, 2) || getPlayerFlag(winner?.teamTwo, 2)}
                  {getPlayerName(winner?.teamOne, 2) || getPlayerName(winner?.teamTwo, 2) || 'Qual winner'}
                </Typography>
              </Fragment>
            ) : (
              <Typography
                className={classes.qualTeam}
                onClick={() =>
                  navigate(
                    `/athlete/${getPlayerid(winner?.teamOne, 1) || getPlayerid(winner?.teamTwo, 1)}/${
                      getPlayerName(winner?.teamOne, 1) || getPlayerName(winner?.teamTwo, 1) || 'Qual winner'?.toLowerCase()?.replace(/\s+/g, '-')
                    }?tab=overview`,
                  )
                }
              >
                {getPlayerFlag(winner?.teamOne, 1) || getPlayerFlag(winner?.teamTwo, 1)}
                {getPlayerName(winner?.teamOne, 1) || getPlayerName(winner?.teamTwo, 1) || 'Qual winner'}
              </Typography>
            )}
          </Box>
        </Box>
      )}
    </Wrapper>
  );
};
export default CustomSeed;
